import Head from 'next/head';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import { isMobile } from 'react-device-detect';

import * as Sentry from '@sentry/nextjs';

import { AIEA_GUEST_DOMAIN } from '@/app/config';

import {
  ArrowCircleRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid';
import { Field, Formik, Form } from 'formik';

import { AIEBadge } from '@/components/elements/AIEBadge';
import { LocalVideo } from '@/components/elements/LocalVideo';
import { Modal } from '@/ui/Modal';
import { Spinner } from '@/ui/Spinner';

import { LoginComponent, LoginCredentials } from '@/components/LoginComponent';
import { Api } from '@/app/api';
import { setAccountCreationState } from 'features/accountCreation/accountCreationSlice';
import { useAppDispatch } from '@/app/hooks';
import { useMutation } from 'react-query';

//#region INTERFACES
interface AccessRequest {
  name: string;
  email: string;
}
//#endregion

//#region PUBLIC API
export default function Login() {
  const router = useRouter();

  const dispatch = useAppDispatch();

  const requestAccessMutation = useMutation(({ name, email }: AccessRequest) =>
    Api.requestAccess(name, email)
  );

  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [accessDetailsValid, setAccessDetailsValid] = useState(true);

  const doLogin = async ({ email, password }: LoginCredentials) => {
    try {
      const user = await Api.login(email, password);
      Sentry.setUser({ email: user.email });
      await router.push('/');
    } catch (e) {
      const up2tomToken = await Api.getUp2TomTokenForCredentials(
        email,
        password
      );
      if (up2tomToken.isSome()) {
        dispatch(
          setAccountCreationState({
            email,
            password,
            token: up2tomToken.get(),
          })
        );
        await router.push(`/terms-and-conditions`);
      } else {
        throw e;
      }
    }
  };

  const [content, setContent] = useState<any>();
  useEffect(() => {
    if (isMobile) {
      setContent(
        <>
          <div className="flex-col items-center w-screen h-screen px-6 pt-4 pb-6 space-y-4 overflow-y-auto divide-y divide-gray-400 font-aiea scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
            <img
              src="/images/logos/aiea-logo-color.png"
              className="mx-auto h-38"
            />

            <div className="flex-col items-center pt-4 bg-white text-aieadark-40">
              <h1 className="text-2xl font-bold text-center text-primary bg-primary"> 
                The AI Employment Agency
              </h1>
              <h2 className="mt-2 font-semibold text-center text-md text-aiealight-600">
                Democratizing Access to Human Expertise
              </h2>

              <button
                onClick={() => setIsRequestingAccess(true)}
                disabled={isRequestingAccess}
                className={`bg-turqoise-blue w-full mt-10 mb-4 py-2 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none ${
                  isRequestingAccess
                    ? ''
                    : 'hover:bg-aiealight-600 hover:text-denim-900'
                } transition-all ease-out duration-300`}
              >
                <p>Request Access</p>
              </button>

              <div className="flex-col w-full">
                <div className="flex items-center justify-between w-full px-4 py-2 mt-8 mr-8 border-2 group focus:outline-none border-aiealight-600">
                  <p className="text-lg font-semibold">
                    What is a Decisioning Digital Twin?
                  </p>
                </div>
                <div className="mx-1 mt-2 text-denim-800">
                  <p className="mt-4 text-sm">
                    An AI Model (or series of models) that responds to a problem
                    or question exactly as its human counterpart would, only in
                    real-time.
                  </p>
                </div>

                <div className="w-full px-4 py-2 mt-8 mr-8 border-2 select-none group focus:outline-none border-aiealight-600">
                  <div className="flex-col w-full h-full">
                    <div className="flex items-center justify-between w-full">
                      <p className="text-lg font-semibold">
                        {'Why Digital Twins?'}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-4 text-sm">
                  Human experts can physically only work in one place at one
                  time, for approximately 8-12 hours a day. Their Digital Twins
                  have none of these physical capacity constraints. The AIEA
                  enables Digital Twins to work in multiple locations, 24/7, 365
                  days a year.
                </p>
                <p className="mt-6 text-sm font-semibold">
                  Via AIEA, organizations access and employ (or deploy) this
                  unlimited source of expertise in 3 steps:
                </p>
                <p className="mt-2 text-sm">
                  <span className="mr-2 font-bold">&#8226;</span> Search for
                  required expertise (anywhere in the world).
                </p>
                <p className="mt-1 text-sm">
                  <span className="mr-2 font-bold">&#8226;</span> Engage the
                  expert (via the platform application form).
                </p>
                <p className="mt-1 text-sm">
                  <span className="mr-2 font-bold">&#8226;</span> Download
                  Digital Twins into your environment.
                </p>
              </div>

              <div className="flex-col p-2 mt-12 space-y-2 text-xs font-semibold text-center text-white rounded-lg bg-denim-900">
                <p>AIEA is not available on this device yet!</p>
                <p>Give it a try on your desktop computer instead.</p>
              </div>

              <a
                className="hover:cursor-pointer"
                href="https://www.merlynn-ai.com/"
                target="blank"
              >
                <img
                  src="/images/logos/powered-by-mit-logo.png"
                  className="h-32 mx-auto mt-4"
                />
              </a>
            </div>

            <Modal open={isRequestingAccess}>
              <div className="flex flex-col items-center transition-all duration-300 ease-in-out border-4 border-gray-200 rounded-md font-aiea">
                <p className="px-8 py-6 text-2xl font-semibold text-center text-denim-900">
                  Would you like to access AIEA?
                </p>
                <p className="pt-6 pb-12 pl-12 pr-8 text-center text-gray-800 text-md">
                  Please provide your name and email address. You will then be
                  contacted to continue registration.
                </p>
                <div className="flex flex-col">
                  {!accessDetailsValid && (
                    <p
                      className={`text-left mx-8 text-xs mb-2 text-red-600 transition-all ease-in-out duration-300`}
                    >
                      A name and valid contact email address are required
                    </p>
                  )}
                  {(requestAccessMutation.isSuccess ||
                    requestAccessMutation.isError) && (
                    <p
                      className={`text-left mx-8 text-xs mb-2 ${
                        requestAccessMutation.isSuccess
                          ? 'text-green-600'
                          : 'text-red-600'
                      } transition-all ease-in-out duration-300`}
                    >
                      {requestAccessMutation.isSuccess
                        ? 'You have successfully requested access to AIEA'
                        : 'Something went wrong. Please try again in a few minutes'}
                    </p>
                  )}
                  <div className="flex items-center mx-8 mb-8 space-x-2">
                    <Formik<AccessRequest>
                      initialValues={{ name: '', email: '' }}
                      onSubmit={({ name, email }, { resetForm }) => {
                        if (
                          name.length > 0 &&
                          email.includes('@') &&
                          email.includes('.')
                        ) {
                          setAccessDetailsValid(true);
                          requestAccessMutation.mutate(
                            { name: name, email: email },
                            {
                              onSuccess: () => {
                                resetForm();
                              },
                            }
                          );
                        } else {
                          setAccessDetailsValid(false);
                        }
                      }}
                    >
                      <Form className="flex flex-1" autoComplete="off">
                        <div className="flex items-center flex-1 gap-x-3">
                          <div className="flex-col space-y-2">
                            <Field
                              autoComplete="off"
                              className="w-full px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 bg-blue-100 focus:bg-blue-200 text-md focus:outline-none"
                              placeholder="Full Name"
                              type="name"
                              name="name"
                            />
                            <Field
                              autoComplete="off"
                              className="w-full px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 bg-blue-100 focus:bg-blue-200 text-md focus:outline-none"
                              placeholder="Email"
                              type="email"
                              name="email"
                            />
                          </div>
                          {requestAccessMutation.isLoading ? (
                            <Spinner className="h-8 text-turqoise-blue" />
                          ) : (
                            // requestAccessMutation.isSuccess ? (
                            //   <CheckIcon className="h-8 transition-all duration-300 ease-out text-turqoise-blue hover:text-turqoise-blue" />
                            <button type="submit">
                              <ArrowCircleRightIcon className="h-8 transition-all duration-300 ease-out text-denim-900 hover:text-turqoise-blue focus:outline-none focus:border-0" />
                            </button>
                          )}
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="flex items-center mb-6 space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
                  <a
                    onClick={() => setIsRequestingAccess(false)}
                    className="text-sm text-center"
                  >
                    Return
                  </a>
                  <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
                </div>
              </div>
            </Modal>
          </div>
        </>
      );
    } else {
      setContent(
        <>
          <div className="flex-col w-screen h-screen px-8 divide-y select-none divide divide-turqoise-blue">
            <div className="z-100">
              <LoginComponent doLogin={doLogin} />
            </div>
            <div className="flex w-full h-full font-aiea">
              <div className="z-50 flex-col items-center w-1/3 mt-8 mb-48 ml-12 overflow-y-auto text-aieadark-400 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
                <div className="flex-col items-center w-full h-full pr-4 bg-white">
                  <h1 className="text-3xl font-bold">
                    The AI Employment Agency
                  </h1>
                  <h2 className="text-lg font-semibold text-aiealight-600">
                    Democratizing Access to Human Expertise
                  </h2>
                  <p className="mt-6 text-sm font-semibold text-denim-900">
                    Solving the skills crisis - one
                    <span className="mx-1 font-bold text-denim-800">
                      Decisioning Digital Twin
                    </span>
                    at a time
                  </p>

                  <div className="flex-col w-full">
                    <div className="flex items-center justify-between w-full py-2 mt-6 mr-8 group focus:outline-none">
                      <p className="text-xl font-semibold">
                        What is a Decisioning Digital Twin?
                      </p>
                    </div>
                    <div className="mx-1 mt-2 text-denim-800">
                      <p className="mt-2">
                        An AI Model (or series of models) that responds to a
                        problem or question exactly as its human counterpart
                        would, only in real-time.
                      </p>
                    </div>

                    <div className="w-full py-2 mt-4 mr-8 select-none group focus:outline-none">
                      <div className="flex-col w-full h-full">
                        <div className="flex items-center justify-between w-full">
                          <p className="text-xl font-semibold">
                            {'Why Digital Twins?'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2">
                      Human experts can physically only work in one place at one
                      time, for approximately 8-12 hours a day. Their Digital
                      Twins have none of these physical capacity constraints.
                      The AIEA enables Digital Twins to work in multiple
                      locations, 24/7, 365 days a year.
                    </p>
                    <p className="mt-6 font-semibold">
                      Via AIEA, organizations access and employ (or deploy) this
                      unlimited source of expertise in 3 steps:
                    </p>
                    <p className="mt-2">
                      <span className="mr-2 font-bold">&#8226;</span> Search for
                      required expertise (anywhere in the world).
                    </p>
                    <p className="mt-1">
                      <span className="mr-2 font-bold">&#8226;</span> Engage the
                      expert (via the platform application form).
                    </p>
                    <p className="mt-1">
                      <span className="mr-2 font-bold">&#8226;</span> Download
                      Digital Twins into your environment.
                    </p>
                  </div>
                  <div className="flex items-center mt-10 space-x-10">
                    <a
                      className={`text-center shadow-lg bg-turqoise-blue w-1/2 py-4 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none ${
                        isRequestingAccess
                          ? ''
                          : 'hover:bg-aiealight-600 hover:text-denim-900 hover:animate-pulse'
                      } transition-all ease-out duration-300`}
                      href={AIEA_GUEST_DOMAIN}
                    >
                      <p>Explore The Agency</p>
                      {/* <p className="text-sm font-light">Search for expertise</p> */}
                    </a>
                    {/* <button
                      onClick={() => setIsRequestingAccess(true)}
                      disabled={isRequestingAccess}
                      className={`bg-turqoise-blue w-full py-2 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none ${isRequestingAccess
                        ? ""
                        : "hover:bg-aiealight-600 hover:text-denim-900"
                        } transition-all ease-out duration-300`}
                    >
                      <p>Join The Agency</p>
                      <p className="text-sm font-light">Sell your expertise</p>
                    </button> */}
                  </div>
                </div>
              </div>

              <div className="w-1/2 m-auto mx-42 h-3/4">
                <LocalVideo localSourcePath="video/AIEAIntro_v2_Reupload.mp4" />
                {/* TODO Allow passing components into local video to insert explore the marketplace button on unsupported browsers.*/}
                {/* <div
                  className="container relative group hover:cursor-pointer"
                  onClick={() => window.open(AIEA_GUEST_DOMAIN, "_self")}
                >
                  <img
                    className="-mt-40 transition-all ease-in-out scale-100 shadow-lg w-200 opacity-90 group-hover:opacity-80 group-hover:blur-xs group-hover:scale-105 group-hover:shadow-2xl group-hover:cursor-pointer 200"
                    src="/images/platform-screen-placeholder.png"
                  >
                  </img>
                  <div className="absolute inset-0 z-10 flex items-center justify-center">
                    <div
                      className={`text-center group-hover:scale-105 opacity-0 group-hover:opacity-100 blur-lg group-hover:blur-none bg-aieadark-500 w-full py-2 text-white font-semibold text-lg px-6 items-center focus:outline-none transition-all ease-in-out 200`}
                    >
                      <p>Explore The Marketplace</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <AIEBadge isColored={true} />

          <a
            className="hover:cursor-pointer"
            href="https://www.merlynn-ai.com/"
            target="blank"
          >
            <img
              src="/images/logos/powered-by-mit-logo.png"
              className="absolute h-32 text-white text-opacity-50 origin-bottom-left transform bottom-2 -right-6 font-aiea scale-aiea"
            />
          </a>

          <div className="transform scale-aiea">
            <Modal open={isModalShowing}>
              <div className="flex flex-col items-center pb-8 transition-all duration-300 ease-in-out border-4 border-gray-200 rounded-md max-h-200 font-aiea">
                <p className="px-8 py-6 text-2xl font-semibold text-center text-denim-900">
                  Login Required
                </p>
                <p className="px-12 pt-6 pb-12 text-lg text-center text-gray-800 w-132">
                  You need to be logged in to access our library of Digital
                  Twins.
                </p>
                <button
                  onClick={() => setIsModalShowing(false)}
                  className="items-center w-56 px-6 py-2 text-lg font-semibold text-white transition-all duration-300 ease-out rounded bg-denim-900 focus:outline-none hover:bg-aiealight-600 hover:text-denim-900"
                >
                  OK
                </button>
              </div>
            </Modal>
            <Modal open={isRequestingAccess}>
              <div className="flex flex-col items-center transition-all duration-300 ease-in-out border-4 border-gray-200 rounded-md max-h-200 font-aiea">
                <p className="px-8 py-6 text-2xl font-semibold text-center text-denim-900">
                  Would you like to access AIEA?
                </p>
                <p className="pt-6 pb-12 pl-12 pr-8 text-lg text-left text-gray-800 w-132">
                  Please provide your name and email address. You will then be
                  contacted to continue registration.
                </p>
                <div className="flex flex-col">
                  {!accessDetailsValid && (
                    <p
                      className={`text-left mx-8 text-xs mb-2 text-red-600 transition-all ease-in-out duration-300`}
                    >
                      A name and valid contact email address are required
                    </p>
                  )}
                  {(requestAccessMutation.isSuccess ||
                    requestAccessMutation.isError) && (
                    <p
                      className={`text-left mx-8 text-xs mb-2 ${
                        requestAccessMutation.isSuccess
                          ? 'text-green-600'
                          : 'text-red-600'
                      } transition-all ease-in-out duration-300`}
                    >
                      {requestAccessMutation.isSuccess
                        ? 'You have successfully requested access to AIEA'
                        : 'Something went wrong. Please try again in a few minutes'}
                    </p>
                  )}
                  <div className="flex items-center mx-8 mb-8 space-x-2 w-101">
                    <Formik<AccessRequest>
                      initialValues={{ name: '', email: '' }}
                      onSubmit={({ name, email }, { resetForm }) => {
                        if (
                          name.length > 0 &&
                          email.includes('@') &&
                          email.includes('.')
                        ) {
                          setAccessDetailsValid(true);
                          requestAccessMutation.mutate(
                            { name: name, email: email },
                            {
                              onSuccess: () => {
                                resetForm();
                              },
                            }
                          );
                        } else {
                          setAccessDetailsValid(false);
                        }
                      }}
                    >
                      <Form className="flex flex-1">
                        <div className="flex items-center flex-1 gap-x-3">
                          <div className="flex-col space-y-2">
                            <Field
                              className="w-full px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 bg-blue-100 focus:bg-blue-200 text-md focus:outline-none"
                              placeholder="Full Name"
                              type="name"
                              name="name"
                            />
                            <Field
                              className="w-full px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 bg-blue-100 focus:bg-blue-200 text-md focus:outline-none"
                              placeholder="Email"
                              type="email"
                              name="email"
                            />
                          </div>
                          {requestAccessMutation.isLoading ? (
                            <Spinner className="h-8 text-turqoise-blue" />
                          ) : (
                            // requestAccessMutation.isSuccess ? (
                            //   <CheckIcon className="h-8 transition-all duration-300 ease-out text-turqoise-blue hover:text-turqoise-blue" />
                            <button type="submit">
                              <ArrowCircleRightIcon className="h-8 transition-all duration-300 ease-out text-denim-900 hover:text-turqoise-blue" />
                            </button>
                          )}
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="flex items-center mb-6 space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
                  <a
                    onClick={() => setIsRequestingAccess(false)}
                    className="text-xs text-center"
                  >
                    Return to log in
                  </a>
                  <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
                </div>
              </div>
            </Modal>
          </div>
        </>
      );
    }
  }, [isModalShowing, isRequestingAccess, requestAccessMutation.data]);

  return (
    <>
      <Head>
        <title>AI Employment Agency | Login</title>
      </Head>

      <div>{content}</div>
    </>
  );
}
//#endregion
