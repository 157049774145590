import Image from "next/image";

import { ArrowCircleRightIcon, CheckIcon } from "@heroicons/react/solid";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { useMutation } from "react-query";
import { Field, Formik, Form } from "formik";

import { Modal } from "@/ui/Modal";
import { Spinner } from "@/ui/Spinner";
import { Api } from "@/app/api";
import { useState } from "react";

export interface LoginCredentials {
  email: string;
  password: string;
}

interface AccessRequest {
  name: string;
  email: string;
}

interface LoginComponentProps {
  doLogin(creds: LoginCredentials): Promise<void> | void;
}

export function LoginComponent({ doLogin }: LoginComponentProps) {
  const requestAccessMutation = useMutation(({ name, email }: AccessRequest) =>
    Api.requestAccess(name, email)
  );
  const requestCredentialsMutation = useMutation((askEmail: string) =>
    Api.requestCredentials(askEmail)
  );
  const loginMutation = useMutation(async (creds: LoginCredentials) =>
    doLogin(creds)
  );

  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [accessDetailsValid, setAccessDetailsValid] = useState(true);

  return (
    <>
      <div
        className={`
        flex justify-between -my-3
      `}
      >
        <div className="transform scale-aiea -ml-10">
          <Image
            src="/images/logos/aiea-logo-color.png"
            width="258.125px"
            height="140px"
          />
        </div>

        <div className="flex justify-center items-center transform scale-aiea -mr-32">
          <Formik<LoginCredentials>
            initialValues={{ email: "", password: "" }}
            onSubmit={({ email, password }) =>
              loginMutation.mutate({ email, password })}
          >
            <Form>
              <div className="flex space-x-4 items-center">
                {loginMutation.isError && (
                  <p className="text-center mr-2 text-sm text-red-800">
                    Incorrect username or password
                  </p>
                )}
                <Field
                  name="email"
                  className="bg-blue-100 focus:bg-blue-200 px-3 py-2.5 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                  placeholder="Email"
                  type="email"
                />
                <Field
                  name="password"
                  className="bg-blue-100 focus:bg-blue-200 px-3 py-2.5 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                  placeholder="Password"
                  type="password"
                />
                <div className="flex-col items-center space-y-2 pr-12">
                  <div className="flex space-x-3 mt-6">
                    <button
                      disabled={loginMutation.isLoading}
                      type="submit"
                      className={`bg-denim-900 w-56 py-2 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none ${loginMutation.isLoading
                        ? ""
                        : "hover:bg-aiealight-600 hover:text-denim-900"
                        } transition-all ease-out duration-300`}
                    >
                      {loginMutation.isLoading ? (
                        <div className="flex mx-auto space-x-2 items-center">
                          <Spinner className="ml-6 h-6" />
                          <p className="text-center whitespace-nowrap">
                            Logging In
                          </p>
                        </div>
                      ) : (
                        <p>Log in</p>
                      )}
                    </button>
                    {/* <div className="flex items-center">
                      <p className="text-sm text-center mx-auto">OR</p>
                    </div> */}
                    <div className="flex items-center space-x-10">
                      {/* <button
                        onClick={() => setIsRequestingAccess(true)}
                        disabled={isRequestingAccess}
                        className={`bg-turqoise-blue w-56 py-2 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none ${isRequestingAccess
                          ? ""
                          : "hover:bg-aiealight-600 hover:text-denim-900"
                          } transition-all ease-out duration-300`}
                      >
                        <p>Request Access</p>
                      </button> */}
                      <a
                        className="hover:cursor-pointer hover:text-denim-800 pl-12"
                        href="https://www.merlynn-ai.com/governancepolicies"
                        target="blank"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                  <div className="flex ml-6 mt-2 items-center space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
                    <a
                      onClick={() => setIsModalShowing(true)}
                      className="text-center text-xs"
                    >
                      Forgot username or password
                    </a>
                    <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      <div className="transform scale-aiea">
        <Modal open={isModalShowing}>
          <div className="flex flex-col items-center max-h-200 font-aiea border-4 border-gray-200 rounded-md transition-all ease-in-out duration-300">
            <p className="text-center font-semibold text-2xl text-denim-900 py-6 px-8">
              Forgot your password?
            </p>
            <p className="text-left text-lg text-gray-800 pt-6 pb-12 pl-12 pr-8 w-132">
              Please fill in the email that you used to register. You will then be
              contacted with next steps to recover your password.
            </p>
            <div className="flex flex-col">
              {(requestCredentialsMutation.isSuccess ||
                requestCredentialsMutation.isError) && (
                  <p
                    className={`text-left mx-8 text-xs mb-2 ${requestCredentialsMutation.isSuccess
                      ? "text-green-600"
                      : "text-red-600"
                      } transition-all ease-in-out duration-300`}
                  >
                    {requestCredentialsMutation.isSuccess
                      ? "Email sent successfully"
                      : "Something went wrong. Please try again in a few minutes"}
                  </p>
                )}
              <div className="flex w-101 mb-8 mx-8 space-x-2 items-center">
                <Formik
                  initialValues={{ askEmail: "" }}
                  onSubmit={({ askEmail }) =>
                    requestCredentialsMutation.mutate(askEmail)
                  }
                >
                  <Form className="flex flex-1">
                    <div className="flex flex-1 gap-x-3 items-center">
                      <Field
                        className="bg-blue-100 w-full focus:bg-blue-200 px-3 py-2 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                        placeholder="Email"
                        type="email"
                        name="askEmail"
                      />
                      {requestCredentialsMutation.isLoading ? (
                        <Spinner className="h-8 text-turqoise-blue" />
                      ) : requestCredentialsMutation.isSuccess ? (
                        <CheckIcon className="h-8 text-turqoise-blue hover:text-turqoise-blue transition-all ease-out duration-300" />
                      ) : (
                        <button type="submit">
                          <ArrowCircleRightIcon className="h-8 text-denim-900 hover:text-turqoise-blue transition-all ease-out duration-300" />
                        </button>
                      )}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="flex items-center mb-6 space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
              <a
                onClick={() => setIsModalShowing(false)}
                className="text-center text-xs"
              >
                Return to log in
              </a>
              <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
            </div>
          </div>
        </Modal>

        <Modal open={isRequestingAccess}>
          <div className="flex flex-col items-center max-h-200 font-aiea border-4 border-gray-200 rounded-md transition-all ease-in-out duration-300">
            <p className="text-center font-semibold text-2xl text-denim-900 py-6 px-8">
              Would you like to access AIEA?
            </p>
            <p className="text-left text-lg text-gray-800 pt-6 pb-12 pl-12 pr-8 w-132">
              Please provide your name and email address. You will then be
              contacted to continue registration.
            </p>
            <div className="flex flex-col">
              {!accessDetailsValid &&
                <p
                  className={`text-left mx-8 text-xs mb-2 text-red-600 transition-all ease-in-out duration-300`}
                >
                  A name and valid contact email address are required
                </p>
              }
              {(requestAccessMutation.isSuccess ||
                requestAccessMutation.isError) && (
                  <p
                    className={`text-left mx-8 text-xs mb-2 ${requestAccessMutation.isSuccess
                      ? "text-green-600"
                      : "text-red-600"
                      } transition-all ease-in-out duration-300`}
                  >
                    {requestAccessMutation.isSuccess
                      ? "You have successfully requested access to AIEA"
                      : "Something went wrong. Please try again in a few minutes"}
                  </p>
                )}
              <div className="flex w-101 mb-8 mx-8 space-x-2 items-center">
                <Formik<AccessRequest>
                  initialValues={{ name: "", email: "" }}
                  onSubmit={({ name, email }, { resetForm }) => {
                    if (name.length > 0 && email.includes('@') && email.includes('.')) {
                      setAccessDetailsValid(true);
                      requestAccessMutation.mutate({ name: name, email: email }, {
                        onSuccess: () => {
                          resetForm();
                        }
                      })
                    }
                    else {
                      setAccessDetailsValid(false);
                    }
                  }}
                >
                  <Form className="flex flex-1">
                    <div className="flex flex-1 gap-x-3 items-center">
                      <div className="flex-col space-y-2">
                        <Field
                          className="bg-blue-100 w-full focus:bg-blue-200 px-3 py-2 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                          placeholder="Full Name"
                          type="name"
                          name="name"
                        />
                        <Field
                          className="bg-blue-100 w-full focus:bg-blue-200 px-3 py-2 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                          placeholder="Email"
                          type="email"
                          name="email"
                        />
                      </div>
                      {requestAccessMutation.isLoading ? (
                        <Spinner className="h-8 text-turqoise-blue" />
                      ) :  // requestAccessMutation.isSuccess ? (
                        //   <CheckIcon className="h-8 text-turqoise-blue hover:text-turqoise-blue transition-all ease-out duration-300" />
                        (
                          <button type="submit">
                            <ArrowCircleRightIcon className="h-8 text-denim-900 hover:text-turqoise-blue transition-all ease-out duration-300" />
                          </button>
                        )}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="flex items-center mb-6 space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
              <a
                onClick={() => setIsRequestingAccess(false)}
                className="text-center text-xs"
              >
                Return to log in
              </a>
              <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
