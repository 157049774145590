import { FC } from "react";

//#region INTERFACES
interface AIEBadgeProps {
  localSourcePath: string;
}
//#endregion

//#region PUBLIC API
export const LocalVideo: FC<AIEBadgeProps> =
  ({ localSourcePath }) => {
    return (
      <video controls width="100%" className="border-2 shadow-xl">
        <source src={localSourcePath} type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video>
    );
  };
//#endregion