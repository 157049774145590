import { FC } from "react";

//#region INTERFACES
interface AIEBadgeProps {
  isColored: boolean;
}
//#endregion

//#region PUBLIC API
export const AIEBadge: FC<AIEBadgeProps> =
  ({ isColored }) => {
    return (
      <div className="absolute bottom-10 left-10 font-aiea text-opacity-50 text-white origin-bottom-left transform scale-aiea">
        <div className="flex-col items-center">
          <div className="flex items-center">
            <img
              src={isColored ? "/images/logos/aie-logo-color.png" : "/images/logos/aie-logo-white.png"}
              className="h-14"
            />
            {/* <p className={`mt-4 font-semibold ${isColored ? "text-denim-900 text-opacity-50" : "text-white"}`}>beta</p> */}
          </div>
        </div>
      </div>
    );
  };
//#endregion