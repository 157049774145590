import { Fragment, FunctionComponent, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export interface ModalProps {
  open: boolean;
  onClose?: (value: boolean) => void;
  scaled?: boolean;
  bg_color?: string;
  shadow?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  open,
  onClose = () => {},
  scaled = true,
  children,
  bg_color = 'bg-white',
  shadow = true,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden transform sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom={`opacity-0 translate-y-4 sm:translate-y-0 ${
              scaled ? 'sm:scale-70' : ''
            }`}
            enterTo={`opacity-100 translate-y-0 ${scaled ? 'sm:scale-75' : ''}`}
            leave="ease-in duration-200"
            leaveFrom={`opacity-100 translate-y-0 ${scaled ? 'sm:scale-75' : ''}`}
            leaveTo={`opacity-0 translate-y-4 sm:translate-y-0 ${
              scaled ? 'sm:scale-70' : ''
            }`}
          >
            <div
              className={`
              inline-block align-bottom ${bg_color} overflow-hidden rounded-lg text-left ${
                shadow ? 'shadow-xl' : ''
              }
              transform transition-all sm:my-8 sm:align-middle ${scaled ? 'scale-75' : ''}
            `}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
